.footerBee_main{
    color: #fff;
    padding: 200px 0;
}

.footerBee_main img{
    max-width: 100%;
    width: 400px;
}

.footerBee_main p{
    text-align: center;
    font-size: 25px;
    font-weight: 500;
}

@media (max-width: 767px) {
    .footerBee_main img {
        max-width: 275px;
        width: 100%;
    }

    .footerBee_main p {
        font-size: 16px;
    }
}

.diversity_text {
    color: #fff;
}

.diversity_text p {
    font-size: 25px;
    font-weight: 500;
}

.diversity {
    color: #fff;
    z-index: 2;
    position: relative;
    padding: 100px 0 100px 0;
}

.diversity p {
    font-size: 25px;
    font-weight: 500;
}

.diversity_main {
    position: relative;
}

.diversity_img {
    position: absolute;
    right: -80px;
    top: 250px;
    width: 1000px;
    height: 350px;
    max-width: 700px;
    background-size: cover;
    background-position: center;
}

.web {
    display: block;
}

.mob {
    display: none;
}


@media (max-width: 767px) {

    .web {
        display: none;
    }

    .mob {
        display: block;
    }

    .diversity_img {
        position: absolute;
        right: 10px;
        width: 1000px;
        height: 148px;
        top: -400px;
        max-width: 356px;
        background-size: cover;
        background-position: center;
    }

    .diver_col_img {
        width: 320px;
        margin-bottom: 170px;
    }

    .benefit {
        padding: 400px 0 550px 0;
    }

    .diversity p {
        font-size: 16px;
        padding: 20px;
        padding-top: 50px;
    }

    .give_overflow {
        margin-top: 150px;
    }

    .diversity {
        padding: 100px 0 100px 0;
    }
}