.top_header {
    background: #73dcff !important;
}

.top_header p {
    margin-bottom: 0;
    padding: 10px 0;
}

.top_header p span:first-child {
    margin-right: 10px;
}

div#root::before {
    background-attachment: fixed;
    background-image: url('../../assets/images/final_bg.webp');
    background-size: cover !important;
    height: 100%;
    left: 0;
    min-height: 1000px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
    content: '';
    width: 100%;
}

div#root {
    position: relative;
}

@media (max-width: 767px) {

    div#root::before {
        background-attachment: scroll;
        height: 100%;
        left: 0;
        min-height: 1000px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: -1;
        bottom: 0;
        padding-bottom: 500px;
        /* background-color: #73dcff; */
    }

    .loader {
        left: 44% !important;
    }

    .top_header p {
        font-size: 12px;
    }

    .top_header p .insta {
        width: 17px;
    }

    .top_header p .email {
        width: 20px;
    }

    .image1 {
        position: relative;
    }
}


.box-c {
    opacity: 0;
}

.box-d {
    opacity: 0;
}

.piece {
    opacity: 0;
}

.bene{
    opacity: 0;
}

.bene_head {
    opacity: 0;
}

.bene_text {
    opacity: 0;
}

.divi {
    opacity: 0;
}

.utili {
    opacity: 0;
}

.poi {
    opacity: 0;
}
.abundance{
    opacity: 0;
}

.coll2{
    opacity: 0;
}

.coll1{
    opacity: 0;
}

.pieceMob{
    opacity: 0;
}

.bene2{
    opacity: 0;
}

.divi2{
    opacity: 0;
}

.poi2{
    opacity: 0;
}

/* --------------------- */


/* --------------------- header --------------------- */
/* .loader-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}  */

/* .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #73dcff;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
} */


.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    display: inline-block;
    padding: 0px;
    opacity: 0.5;
    border: 3px solid #09acfd;
    -webkit-animation: loader 1s ease-in-out infinite alternate;
    animation: loader 1s ease-in-out infinite alternate;
}

.loader:before {
    content: " ";
    position: absolute;
    z-index: -1;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border: 3px solid #09acfd;
}

.loader:after {
    content: " ";
    position: absolute;
    z-index: -1;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border: 3px solid #09acfd;
}

@keyframes loader {
    from {
        transform: rotate(0deg) scale(1, 1);
        border-radius: 0px;
    }

    to {
        transform: rotate(360deg) scale(0, 0);
        border-radius: 50px;
    }
}

@-webkit-keyframes loader {
    from {
        -webkit-transform: rotate(0deg) scale(1, 1);
        border-radius: 0px;
    }

    to {
        -webkit-transform: rotate(360deg) scale(0, 0);
        border-radius: 50px;
    }
}