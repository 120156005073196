@media (max-width: 767px) {
    .mob{
        display: block;
    }

    .header-section {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        border-top: 1px solid #00000038;
        margin-top: -1px;
        background-color: #73dcff !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        padding-top: 0px; 
        padding-bottom: 0px;
        transition: all 0.2s ease;
    }

    div#basic-navbar-nav {
        padding: 12px !important;
    }

}

.mob{
    display: none;
}

.navbar-brand img {
    width: 100px;
}

.navbar-nav .nav-link {
    color: #fff !important;
    margin-right: 30px;
    cursor: pointer;
}

.get_touch_btn2 {
    border-radius: 10px;
    background: #73dcff;
    box-shadow: 0px 0px 18px rgb(115 220 255 / 60%);
    padding: 10px 30px;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    border-color: black;
    border: 1px solid;
}

.navbar-light .navbar-toggler {
    background-color: #73dcff;
    border: none !important;
}