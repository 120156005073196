.contact{
    padding: 40px 0;
    position: relative;
}

.contact::before {
    content: '';
    background-image: url('../../assets/images/11.webp');
    position: absolute;
    left: 0;
    top: 65%;
    width: 100%;
    height: 115px;
    max-width: 565px;
    background-size: cover;
    background-position: center;
    z-index: -1;
}

.contact_section h1{
    color: #fff;
    font-weight: 600;
}

.contact_info p{
    color: #fff;
}

.contact_info p img{
    margin-right: 10px;
}

.form-group label{
    color: #fff !important;
}

.form-control{
    background: transparent !important;
    border: 3px solid #FFC53C !important;
}

.form-control::placeholder{
    color: rgba(255, 255, 255, 0.585) !important;
}

input, textarea{
    color: #fff !important;
}

.form-control:focus{
    box-shadow: none !important;
    border-color: #FFC53C !important;
}


@media (max-width: 1024px) {
    .contact_section h1 {
        font-size: 35px;
    }

    .contact_info p {
        font-size: 13px;
    }
}

.fill_out {
    display: block;
    color: red;
  }