.get_touch_btn {
    border-radius: 10px;
    background: #73dcff;
    box-shadow: 0px 0px 18px rgb(115 220 255 / 60%);
    border: none;
    padding: 10px 30px;
    font-size: 13px;
    font-weight: 600;
}

.bio_text,
.bio_text1 {
    color: #fff;
    text-align: center;
    margin: 100px 0;
}

.bio_main {
    position: relative;
    overflow: auto !important;
}


.bio_main .bio_img1 {
    position: absolute;
    left: 0;
    top: 10%;
    width: 958px;
    height: 330px;
    max-width: 440px;
    background-size: cover;
    background-position: center;
}

.bio_main .bio_img2 {
    position: absolute;
    right: 150px;
    bottom: 27%;
    width: 958px;
    height: 330px;
    max-width: 430px;
    background-size: cover;
    background-position: center;
}

.bio_second_text {
    position: relative;
    z-index: 2;
    padding-top: 150px;
}

.bio_text p,
.bio_text1 p {
    font-size: 25px;
    font-weight: 500;
    margin-top: 20px;
}

.bio_main {
    padding: 100px 0;
}

.bio_text h1 {
    font-size: 45px;
    font-weight: 600;
    font-style: italic;
}

.bio_text h6 {
    font-size: 23px;
    font-weight: 100;
}


@media (max-width: 767px) {
    .bio_main .direction {
        flex-direction: column-reverse;
    }

    .bio_main .bio_img1 {
        width: 360px;
        height: 260px;
    }

    .bio_main .bio_img2 {
        right: 90px;
        height: 230px;
        max-width: 210px;
    }

    .bio_text p, .bio_text1 p {
        font-size: 17px;
        padding: 20px;
    }
}


@media (max-width: 1024px) {
    .bio_main {
        height: auto;
        margin-top: 35px;
    }

    .bio_main .direction {
        align-items: center;
    }

    .bio_text h1 {
        font-size: 35px;
    }

    .bio_text h6 {
        font-size: 20px;
    }

    .bio_text p {
        font-size: 16px;
    }

    .bio_text button {
        margin-bottom: 30px;
    }
}