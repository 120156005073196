@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,500&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}



body {
    font-family: 'Poppins', sans-serif;
    background: #00212c !important;
    min-height: 9000px;
}

.lock{
    overflow-y: hidden;
}

.App{
    overflow: hidden !important;
}

img {
    max-width: 100%;
}

/* ----------------------------------  */

.animation {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    white-space: nowrap;
    background-color: #fff;
    color: white;
    overflow: hidden;
    justify-content: center;
    z-index: 10000000;
    width: 100%;
    animation: animatebg 10s linear infinite;
}

.animate {
    font-size: 100px;
    text-transform: uppercase;
    color: #e0e0e0;
    animation: animate 5s linear infinite;
}

@keyframes animate {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
        display: none;
        opacity: 0;
        visibility: hidden;
    }
}