.points_main {
    color: #fff;
    position: relative;
    padding: 200px 0 200px 0;
}

/* .point_img1 {
    position: absolute;
    left: 66%;
    top: 40%;
    width: 1000px;
    height: 345px;
    max-width: 490px;
    background-size: cover;
    background-position: center;
} */

.point_img2 {
    position: absolute;
    left: -40px;
    top: -900px;
    width: 1000px;
    height: 300px;
    max-width: 300px;
    background-size: cover;
    background-position: center;
}


.points_main ul {
    z-index: 2;
}

.points_main ul li {
    font-size: 25px;
    margin-bottom: 10px;
    max-width: 900px;
    z-index: 2;
    font-weight: 500;
}

.points_main ul li::marker {
    color: #d9b71a;
    font-size: 25px;
}


.main-p-img {
    position: relative;
    z-index: -1;
}

.main-p-ul {
    position: relative;
    z-index: 1;
}

.web {
    display: block;
}

.mob {
    display: none;
}

@media (max-width: 767px) {

    .web {
        display: none;
    }

    .mob {
        display: block;
    }

    /* .point_img1 {
        position: absolute;
        left: 0;
        top: 42%;
        width: 1000px;
        height: 260px;
        max-width: 360px;
        background-size: cover;
        background-position: center;
        opacity: 0.6;
    } */

    .point_img2 {
        position: absolute;
        left: 0px;
        top: -70px;
        width: 1000px;
        height: 250px;
        max-width: 220px;
        background-size: cover;
        background-position: center;
    }

    .points_main ul {
        z-index: 2;
        padding: 40px;
    }

    .points_main ul li {
        font-size: 15px;
        margin-bottom: 0 !important;
    }

    .points_main ul{
        padding-top: 170px !important;
    }
}