.benefit_main {
    padding: 100px 0;
    position: relative;
}

.benefit_img1 {
    position: absolute;
    left: 0;
    top: 350px;
    width: 958px;
    height: 380px;
    max-width: 490px;
    background-size: cover;
    background-position: center;
}

.abun_img1 {
    position: absolute;
    right: 0;
    top: 120px;
    width: 958px;
    height: 380px;
    max-width: 490px;
    background-size: cover;
    background-position: center;
}

.welcome_benefit_head h1 {
    text-align: center;
    color: #fff;
    font-size: 60px;
    font-style: italic;
    margin-bottom: 10px;
    font-weight: bold;
}

.welcome_benefit_head h1 span {
    color: #D9B71A;
}

.benefit {
    padding: 150px 0 100px 0;
    color: #fff;
}

.benefit_text h1 {
    font-size: 45px;
    font-weight: 600;
    font-style: italic;
}

.benefit_text p {
    font-size: 25px;
    font-weight: 500;
    margin-top: 15px;
}

.benefit_img img {
    width: 460px;
}

.benefit_img {
    text-align: center;
}

@media (max-width: 1024px) {
    .benefit {
        height: auto;
    }

    .welcome_benefit_head h1 {
        font-size: 37px;
        margin-top: 30px;
        z-index: 1;
    }

    .benefit_text h1 {
        font-size: 35px;
    }

    .benefit_text p {
        padding-right: 0;
        font-size: 17px;
    }

    .benefit_text button {
        margin-bottom: 30px;
    }
}

.web {
    display: block;
}

.mob {
    display: none;
}

@media (max-width: 767px) {

    .web {
        display: none;
    }

    .mob {
        display: block;
    }

    .benefit_img1 {
        position: absolute;
        left: 0;
        top: 120px;
        width: 961px;
        height: 260px;
        max-width: 360px;
        background-size: cover;
        background-position: center;
    }

    .benefit_img {
        position: absolute;
        left: 0%;
        top: -120px;
        width: 1000px;
        height: 200px;
        max-width: 200px;
        background-size: cover;
        background-position: center;
    }

    .bio_last_img {
        width: 290px;
        margin-top: 510px;
    }

    .flower_gif {
        width: 100px;
    }

    .benefit_text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        padding: 20px;
        margin: 60px 0;
    }
}