.piece_text {
    color: #fff;
    position: relative;
    z-index: 2;
}

.piece_text p {
    font-size: 25px;
    font-weight: 500;
}

.pieceArt_text {
    color: #fff;
    text-align: center;
}

.pieceArt_text p {
    font-size: 25px;
    font-weight: 300;
}

.piece_art {
    position: relative;
    padding: 100px 0;
}

.piece_bg {
    /* position: relative; */
    z-index: 1;
}

.piece_bg img {
    position: absolute;
    right: 0;
    bottom: 700px;
    height: 350px;
    max-width: 400px;
    background-size: cover;
    background-position: center;
    z-index: -1;
}

.web{
    display: block;
}

.mob{
    display: none;
}


@media (max-width: 767px) {

    .web{
        display: none;
    }
    
    .mob{
        display: block;
    }

    .piece_art .first_img {
        max-width: 275px;
        margin-bottom: 130px;
    }

    .piece_text p {
        font-size: 17px;
        padding-top: 60px;
    }

    .piece_bg img {
        position: absolute;
        right: 15px;
        bottom: 200px;
        height: 220px;
        max-width: 260px;
        background-size: cover;
        background-position: center;
    }
    
    .piece_text {
        position: relative;
        z-index: 2;
        padding: 20px;
    }

    .overflow_margin{
        width: 100%;
        overflow-x: hidden !important;
    }
}


.utilities_main {
    color: #fff;
    position: relative;
    padding-top: 200px
}

.utility_img {
    position: absolute;
    right: 0;
    top: 27%;
    width: 1000px;
    height: 345px;
    max-width: 490px;
    background-size: cover;
    background-position: center;
}


.utilities_main h1 {
    font-size: 45px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    font-style: italic;
}

.utilities_main p {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
}

.utilities_main p span {
    font-size: 35px;
    font-weight: bold;
}

.utilities_text {
    z-index: 2;
    max-width: 950px;
    margin: auto;
    position: relative;
}

.utilities_text p {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
}

.elastic_utilities h4{
    font-size: 45px;
    text-transform: uppercase;
    font-weight: 600;
    font-style: italic;
}

.elastic_utilities h4 span{
    color: #D9B71A;
}

.elastic_utilities{
    padding-top: 80px;
}


@media (max-width: 767px) {
    .utility_img {
        position: absolute;
        right: 0;
        top: -100px;
        width: 1000px;
        height: 270px;
        max-width: 350px;
        background-size: cover;
        background-position: center;
    }

    .utilities_main h1 {
        font-size: 35px;
    }

    .utilities_main p {
        font-size: 17px;
    }

    .elastic_utilities h4 {
        font-size: 35px;
    }
}